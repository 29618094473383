@import 'variables.scss';

.my-\[30px\] {
  margin-block: 30px;
}

.mb-\[80px\] {
  margin-bottom: 80px;
}

.content {
  text-align: left;
  color: #222222;

  a {
    color: $green;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}
