@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h3 {
  font-family: 'Montserrat';
  margin-bottom: 40px;
}

.artibot-wrapper--3PaNf .artibot-launcher--dBP9o > svg {
  margin-inline: auto;
}
